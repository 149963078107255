import React from "react"
import styles from "../css/banner.module.css"
import Slide from "react-reveal/Slide"

const Banner = ({ titleUp, titleDown, info, children }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.center}>
        <Slide left duration={2000}>
          <h1 className={styles.titleUp}>{titleUp}</h1>
        </Slide>
        <Slide right duration={2000}>
          <h1>{titleDown}</h1>
        </Slide>
      </div>
      <p>{info}</p>
      {children}
    </div>
  )
}

export default Banner
