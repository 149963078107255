import React from "react"
import Title from "../Title"
import styled from "styled-components"
import styles from "../../css/services.module.css"
import services from "../../constants/services"
import Fade from "react-reveal/Fade"

const Services = () => {
  return (
    <section className={styles.services}>
      <Title title="Nuestros" subtitle="Servicios" />
      <div className={styles.center}>
        {services.map((item, index) => {
          return (
            <Fade bottom>
              <article key={index} className={styles.service}>
                <span> {item.icon}</span>
                <h4>{item.title}</h4>
              </article>
            </Fade>
          )
        })}
      </div>
    </section>
  )
}

export default styled(Services)``
