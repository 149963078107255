import React from "react"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import Banner from "../components/Banner"
import About from "../components/Home/About"
import Services from "../components/Home/Services"
import { Link, graphql } from "gatsby"
import FeaturedProjects from "../components/Projects/FeaturedProjects"
import Fade from "react-reveal/Fade"

import SEO from "../components/SEO"

export default ({ data }) => (
  <Layout>
    <SEO
      title="Perspectiva Studio"
      description="Arquitectura, Construcción y Renderizado"
    />
    <StyledHero homeFlag="true" img={data.araneumBcg.childImageSharp.fluid}>
      <Banner
        titleUp="Arquitectura"
        titleDown="con Perspectiva"
        info="Desarrollemos los espacios que siempre soñaste."
      >
        <Fade bottom delay={300} duration={2000}>
          <Link to="/contact" className="btn-white">
            Contáctanos
          </Link>
        </Fade>
      </Banner>
    </StyledHero>
    <About />
    <Services />
    <FeaturedProjects />
  </Layout>
)

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    araneumBcg: file(relativePath: { eq: "araneum.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
