import React from "react"
import Project from "../Projects/Project"
import { useStaticQuery, graphql } from "gatsby"
import Title from "../Title"
import styles from "../../css/items.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const getProjects = graphql`
  query {
    featuredProjects: allContentfulProjects(
      filter: { featuredFlag: { eq: true } }
    ) {
      edges {
        node {
          name
          slug
          location
          category
          services
          contentful_id
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          coverImg {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
const FeaturedProjects = () => {
  const response = useStaticQuery(getProjects)
  const projects = response.featuredProjects.edges
  return (
    <section className={styles.projects}>
      <Title title="Nuestros" subtitle="Proyectos" />
      <div className={styles.center}>
        {projects.map(({ node }) => {
          return <Project key={node.contentful_id} project={node} />
        })}
      </div>
      <AniLink fade to="/projects" className="btn-primary">
        Más Proyectos
      </AniLink>
    </section>
  )
}

export default FeaturedProjects
