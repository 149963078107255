import React from "react"
import { FaPencilAlt, FaBuilding, FaDesktop } from "react-icons/fa"

export default [
  {
    icon: <FaPencilAlt />,
    title: "Arquitectura",
    text:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Obcaecati, earum. ",
  },
  {
    icon: <FaBuilding />,
    title: "Construcción",
    text:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Obcaecati, earum. ",
  },
  {
    icon: <FaDesktop />,
    title: "Renderizado",
    text:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Obcaecati, earum. ",
  },
]
