import React from "react"
import Title from "../Title"
import styles from "../../css/about.module.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Slide from "react-reveal/Slide"

const getAbout = graphql`
  query aboutImage {
    aboutImage: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const About = () => {
  const { aboutImage } = useStaticQuery(getAbout)
  return (
    <section className={styles.about}>
      <Title title="Nuestra" subtitle="Firma" />

      {/* Classes in css moudules are hyphened, but are exported as CamelCase */}
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <Slide left>
              <Img
                fluid={aboutImage.childImageSharp.fluid}
                alt="About Company"
              />
            </Slide>
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <Slide bottom>
            <h4>Hacemos la diferencia</h4>
            <p>
              Somos un grupo de expertos que escucha tus ideas, entiende tu
              estilo de vida y lo traduce en espacios arquitectónicos únicos.
            </p>
            <p>
              Contacta con nosotros, cuéntanos tu idea y nosotros nos encargamos
              de hacer realidad el proyecto de tus sueños.
            </p>
            <Link
              to="/contact"
              type="button"
              className={`${styles.btn} btn-primary`}
            >
              Contáctanos
            </Link>
            <Link
              to="/projects"
              type="button"
              className={`${styles.btn} btn-secondary`}
            >
              Ver Proyectos
            </Link>
          </Slide>
        </article>
      </div>
    </section>
  )
}

export default About
